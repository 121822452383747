import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import moment from 'moment'
import {mainConfig} from '../../../config/mainConfig' //main config file where we set api link
import {Skeleton, Table, Pagination, Row, Col} from 'antd'
import {Button} from 'react-bootstrap-v5'
import { render } from '@testing-library/react'
import { ArrowUpOutlined, ArrowDownOutlined, ColumnWidthOutlined, RightCircleTwoTone } from '@ant-design/icons'
 import { useHistory } from "react-router-dom";
 import { Tooltip } from "antd";

let token = '',
  usrRole = ''
const HomeWrapper: FC = () => {
  const intl = useIntl()
  const [loading, setloading] = useState<any>(true)
  // const [serchedSupplier, setSearchedsupplier] = useState<any[]>([])
  // const [serchedProduct, setSearchedProduct] = useState<any[]>([])
  const [allPO, setAllPO] = useState<any[]>([])
  const [assigned_to_me, setAssigned_to_me] = useState<any[]>([])
  const [assigned_by_me, setAssigned_by_me] = useState<any[]>([])
  const [flag, setFlag] = useState<any>(false)
  const [allBackground, setallBackground] = useState<any[]>([])
  const [allComment, setallComment] = useState<any[]>([])
  const [allTrends, setallTrends] = useState<any[]>([])
  const [allRunrateCount,setAllRunrateCount] = useState<any>("")
  const [belowRunrate,setBelowRunrate]= useState<any>("")
  const [aboveRunrate,setAboveRunrate]= useState<any>("")

  const history = useHistory()


  useEffect(() => {
    if (flag) {
      return
    }
    getPO() //  get all pos details
    getPendingTask() //get pending task details
    setFlag(true)
  }, [])

  useEffect(()=>{
    getRunrate()

  },[])

  
  const getPO = () => {
    setloading(true)
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-product-latest-background`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.status != 200) {
          localStorage.clear()
          window.location.reload()
        }
        response.json().then(function (data) {
          if (data.success == true) {
            // setAllPO(data.data) //sets all pos
            setallBackground(data?.data?.background)
          }
        })
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-top-comments`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // if (response.status != 200) {
        //   localStorage.clear()
        //   window.location.reload()
        // }
        response.json().then(function (data) {
          if (data.success == true) {
            // setAllPO(data.data) //sets all pos
            // console.log('Fetch Error :-S', data?.data?.comments)
            setallComment(data?.data?.comments)
          }
        })
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-top-product-trends`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        // if (response.status != 200) {
        //   localStorage.clear()
        //   window.location.reload()
        // }
        response.json().then(function (data) {
          if (data.success == true) {
            // setAllPO(data.data) //sets all pos
            setallTrends(data?.data?.trends)
            setloading(false)
          }
        })
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    // setloading(false)
  }

  const getRunrate = () => {
    setloading(true)
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-runrate-product-history`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        response.json().then(function (data) {
          if (data.success == true) {
            setAllRunrateCount(data.data.total_runrate_product_count)
            setBelowRunrate(data.data.runrate_below_product_count)
            setAboveRunrate(data.data.runrate_above_product_count)
          }
        })
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    // setloading(false)
  }
 

  const getPendingTask = () => {
    setloading(true)
    fetch(`${mainConfig.MAIN_ENDPOINT}/api/get-pending-tasks`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.status != 200) {
          localStorage.clear()
          window.location.reload()
        }
        response.json().then(function (data) {
          if (data.success == true) {
            setAssigned_to_me(data.data.assigned_to_me)
            setAssigned_by_me(data.data.assigned_by_me)
          }
        })
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err)
      })
    // setloading(false)
  }
 
  const column = [
    {
      title: 'Agg Code',
      dataIndex: 'ac4',
      key: 'ac4',
      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
      
    },
    {
      title: 'Spot Code',
      dataIndex: 'bc_code',
      key: 'bc_code',
      width:120,
      sorter: (a: any, b: any) => a.bc_code.localeCompare(b.bc_code),
      render: (item: any, records: any) => {
        return (
          <Link
            to={{
              pathname: '/product',
              state: { prod_id: records.productpage_id, data: records},
            }}
          >
            {item}
          </Link>
        )
      },
    },
    {
      title: 'Trend',
      dataIndex: 'trend',
      key: 'trend',
      sorter: (a: any, b: any) => a.trend.localeCompare(b.trend),
      render: (trend: string) => {
        if (trend === 'Up') {
          return <ArrowUpOutlined style={{ color: 'green', margin: '0' }} />;
        }
        if (trend === 'Down') {
          return <ArrowDownOutlined style={{ color: 'red', margin: '0' }} />;
        }
        if (trend ==="Steady"){
          return <ColumnWidthOutlined style={{ color: 'orange', margin: ' 0' }} />
        }
        return trend;  
      },
    },
    {
      title: 'Added By',
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a: any, b: any) => a.created_by.localeCompare(b.created_by),
    },
    {
      title: 'Added On',
      dataIndex: 'created_on',
      key: 'created_on',

      render: (item: string) => {
        if (!item) return <>-</>
        return moment(item).format('DD-MMM-YYYY')
      },
      sorter: (a: any, b: any) => moment(a.created_on).unix() - moment(b.created_on).unix(),
    },
  ]

  const columnTemp = [
    {
      title: 'Agg Code',
      dataIndex: 'ac4',
      key: 'ac4',
      width:100,

      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
      
    },
    {
      title: 'Spot Code',
      dataIndex: 'bc_code',
      key: 'bc_code',
      width:100,
      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
      render: (item: any, records: any) => {
        return (
          <Link
            to={{
              pathname: '/product',
              state: { prod_id: records.productpage_id, data: records},
            }}
          >
            {item}
          </Link>
        )
      },
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      sorter: (a: any, b: any) => a.task.localeCompare(b.task),
    },
    {
      title: 'Assigned By',
      dataIndex: 'assigned_by',
      key: 'assigned_by',
      sorter: (a: any, b: any) => a.assigned_by.localeCompare(b.assigned_by),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      sorter: (a: any, b: any) => a.assigned_to.localeCompare(b.assigned_to),
    },
    {
      title: 'Assigned On',
      dataIndex: 'created_on',
      key: 'created_on',

      render: (item: string) => {
        if (item) {
          // console.log(':::::::item :::',item)
          return moment(item).format('DD-MMM-YYYY')
        }
      },
      sorter: (a: any, b: any) => moment(a.created_on).unix() - moment(b.created_on).unix(),
    },
  ]

  const columnComment = [
    {
      title: 'Agg Code',
      dataIndex: 'ac4',
      key: 'ac4',
      width:100,

      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
      
    },
    {
      title: 'Spot Code',
      dataIndex: 'bc_code',
      key: 'bc_code',
      width:120,
      sorter: (a: any, b: any) => a.bc_code.localeCompare(b.bc_code),
      render: (item: any, records: any) => {
        return (
          <Link
            to={{
              pathname: '/product',
              state: { prod_id: records.productpage_id, data: records},
            }}
          >
            {item}
          </Link>
        )
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      sorter: (a: any, b: any) => a.comment.localeCompare(b.comment),
    },
    {
      title: 'Added By',
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a: any, b: any) => a.created_by.localeCompare(b.created_by),
    },
    {
      title: 'Added On',
      dataIndex: 'created_on',
      key: 'created_on',

      render: (item: string) => {
        if (item) {
          // console.log(':::::::item :::',item)
          return moment(item).format('DD-MMM-YYYY')
        }
      },
      sorter: (a: any, b: any) => moment(a.created_on).unix() - moment(b.created_on).unix(),
    },
  ]

  const columnBackGround = [
    {
      title: 'Agg Code',
      dataIndex: 'ac4',
      key: 'ac4',
      width:120,
      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
       
    },
    {
      title: 'Spot Code',
      dataIndex: 'bc_code',
      key: 'bc_code',
      width:120,
      sorter: (a: any, b: any) => a.bc_code.localeCompare(b.bc_code),
      render: (item: any, records: any) => {
        return (
          <Link
            to={{
              pathname: '/product',
              state: { prod_id: records.productpage_id, data: records},
            }}
          >
            {item}
          </Link>
        )
      },
    },
    {
      title: 'Information',
      dataIndex: 'info',
      key: 'info',
      width:300,
      sorter: (a: any, b: any) => a.info.localeCompare(b.info),
    },
    {
      title: 'Added By',
      dataIndex: 'added_by',
      key: 'added_by',
      sorter: (a: any, b: any) => a.added_by.localeCompare(b.added_by),
    },
    {
      title: 'Added On',
      dataIndex: 'created_at',
      key: 'created_at',

      render: (item: string) => {
        if (item) {
          // console.log(':::::::item :::',item)
          return moment(item).format('DD-MMM-YYYY')
        }
      },
      sorter: (a: any, b: any) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  ]

  const columnAssignByme = [
    {
      title: 'Agg Code',
      dataIndex: 'ac4',
      key: 'ac4',
      sorter: (a: any, b: any) => a.ac4.localeCompare(b.ac4),
       
    },
    {
      title: 'Spot Code',
      dataIndex: 'bc_code',
      key: 'bc_code',
      sorter: (a: any, b: any) => a.bc_code.localeCompare(b.bc_code),
      render: (item: any, records: any) => {
        return (
          <Link
            to={{
              pathname: '/product',
              state: {prod_id: records.productpage_id},
            }}
          >
            {item}
          </Link>
        )
      },
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
      sorter: (a: any, b: any) => a.task.localeCompare(b.task),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      sorter: (a: any, b: any) => a.assigned_to.localeCompare(b.assigned_to),
      
    },
    {
      title: 'Assigned On',
      dataIndex: 'created_on',
      key: 'created_on',

      render: (item: string) => {
        if (item) {
          // console.log(':::::::item :::',item)
          return moment(item).format('DD-MMM-YYYY')
        }
      },
      sorter: (a: any, b: any) => moment(a.created_on).unix() - moment(b.created_on).unix(),
    },
  ]


  const handleRidrect =()=>{
    history.push('/runrate')
  }
  token = JSON.parse(localStorage.getItem('portalUserDataToken') || '')
  usrRole = JSON.parse(localStorage.getItem('portalUserDataRole') || '')

  return (
    <React.Fragment>
      {loading == true ? (
        <>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
          <Skeleton></Skeleton>
        </>
      ) : (
        <React.Fragment>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
          <div className={`card mx-0 px-0 `}>
          <div className='card-header border-0 pt-2'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span
                          className='card-label fw-bolder fs-3 mb-1'
                          style={{color: 'rgb(138, 38, 80)'}}
                        >
                          <>
                            <span>Runrate Summary </span>
                          </>
                        </span>
                      </h3>
          </div>
             

            <div className='card mt-1' style={{width:'90%',margin:'0 auto',padding:10}}>  
                  <Row className='d-flex form-control-sm border flex-wrap'>
                    <Col  xs={8} sm={8} md={8} lg={8} xl={7} style={{fontSize:16}}>
                    All IRP Products 
                      <span className='text-dark'>
                      <div><b>{allRunrateCount}</b></div>
                      </span>
                    </Col>
                    <Col  xs={8} sm={8} md={8} lg={8} xl={7} style={{fontSize:16}}>
                       Below Run-Rate Products       
                                   <span className='text-dark'>
                        <div style={{color:'red'}}>{belowRunrate}</div>
                        </span>
                    </Col>
                    <Col  xs={8} sm={8} md={8} lg={8} xl={7} style={{fontSize:16}}>
                    Above Run-Rate Products  <span className='text-dark'>
                       <div style={{color:'green'}}> {aboveRunrate}</div>
                        </span>
                    </Col>
                    <Col  xs={8} sm={8} md={8} lg={8} xl={3} style={{fontSize:16}}>

                    <div style={{paddingTop:'10px'}}>
                    <Tooltip title="Click here to view the detailed report.">

                    <RightCircleTwoTone  style={{fontSize:'24px'}} onClick={handleRidrect}/>
                    </Tooltip>
                    </div>
                    </Col>
                  </Row>
            </div>
          
          </div>
          <div className='row gy-5 gx-xl-10' style={{marginTop: '1%'}}>
            <div className='col-xl-6'>
              <div className='card-xxl-stretch mb-5 mb-xxl-8'>
                <div className='row gy-5 g-xl-7'>
                  <div className='card-xxl-stretch mb-4 mb-xxl-7'>
                    <div className='row gy-5 g-xl-7'>
                      <div className={`card mx-0 px-0`}>
                        <div className='card-header border-0 pt-2'>
                          <h3 className='card-title align-items-start flex-column'>
                            <span
                              className='card-label fw-bolder fs-3 mb-1'
                              style={{color: 'rgb(138, 38, 80)'}}
                            >
                              {usrRole.includes('Administrator') ? (
                                <>
                                  <span>Background </span>
                                </>
                              ) : (
                                `Background`
                              )}
                            </span>
                          </h3>
                        </div>
                        <div className='card-body gy-4 m-0 h-350px' 
                        //  style={{overflowY: 'scroll'}}
                         >
                          <div className='h-250px' style={{overflow: 'auto'}}>
                            {/* height: '300px', */}
                            {allBackground &&
                              <Table
                              columns={columnBackGround}
                              dataSource={allBackground}
                              loading={loading}
                              pagination={false}
                              scroll={{
                                x: 'auto',
                                y: '600px',
                              }}
                            />
                              }
                          </div>
                          {allBackground && allBackground.length > 0 && (

                        <Link to={{pathname: '/allbackgroundlist'}}>
                          <Button className='btn btn-sm btn-primary'>View More</Button>
                        </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-xl-12'>
                  <div className='card-xxl-stretch mb-4 mb-xxl-7'>
                    <div className='row gy-5 g-xl-7'>
                      <div className={`card mx-0 px-0`}>
                        <div className='card-header border-0 pt-2 '>
                          <h3 className='card-title align-items-start flex-column'>
                            <span
                              className='card-label fw-bolder fs-3 mb-1'
                              style={{color: 'rgb(138, 38, 80)'}}
                            >
                              Comments
                            </span>
                          </h3>
                        </div>
                        <div
                          className='card-body py-3 m-0'
                          style={{height: allComment.length > 0 ? '395px' : '448px'}}
                        >
                          <div className='h-250px' style={{overflow: 'auto'}}>
                          
                            <Table
                              columns={columnComment}
                              dataSource={allComment}
                              loading={loading}
                              pagination={false}
                              scroll={{
                                x: 'auto',
                                y: '600px',
                              }}
                            />
                          </div>
                        </div>
                        {allComment && allComment.length > 0 && (
                          <div className='p-2'>
                            <Link to={{pathname: '/comments'}}>
                              <button className='btn btn-sm btn-primary'>View More</button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='card-xxl-stretch mb-4 mb-xxl-7'>
                <div className='row gy-5 g-xl-7'>
                  <div className={`card mx-0 px-0 `}>
                    <div className='card-header border-0 pt-2'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span
                          className='card-label fw-bolder fs-3 mb-1'
                          style={{color: 'rgb(138, 38, 80)'}}
                        >
                          <>
                            <span>Market Trends </span>
                          </>
                        </span>
                      </h3>
                    </div>
                    <div className='card-body gy-4 m-0 h-350px'>
                      {/* card-body py-4 */}
                      <div className='h-250px' style={{overflow: 'auto'}}>
                      
                        <Table
                          columns={column}
                          dataSource={allTrends}
                          loading={loading}
                         
                          scroll={{
                            x: 'auto',
                            y: '600px',
                          }}
                          pagination={{defaultPageSize: 10, hideOnSinglePage: true}}
                        />
                      </div>
                      <div>
                        <Link to={{pathname: '/allmarkettrend'}}>
                          <Button className='btn btn-sm btn-primary'>View More</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-12'>
                <div className='card-xxl-stretch mb-4 mb-xxl-7'>
                  <div className='row gy-5 g-xl-7'>
                    <div className={`card mx-0 px-0`}>
                      <div className='card-header border-0 pt-2 '>
                        <h3 className='card-title align-items-start flex-column'>
                          <span
                            className='card-label fw-bolder fs-3 mb-1'
                            style={{color: 'rgb(138, 38, 80)'}}
                          >
                            {usrRole.includes('Administrator')
                              ? 'All Pending Tasks'
                              : `My Pending Tasks`}
                          </span>
                        </h3>
                      </div>
                      <div className='card-body py-1 m-0'>
                        {/* card-body gy-4 m-2 h-350px */}
                        <div className='table-responsive h-500px table-scroll'>
                          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
                            <li className='nav-item'>
                              <a
                                className='nav-link text-active-primary pb-4 active'
                                data-bs-toggle='tab'
                                href='#Assigned_to_me'
                              >
                                {usrRole.includes('Administrator')
                                  ? 'Assigned to all users'
                                  : 'Assigned to me'}
                              </a>
                            </li>
                            <li className='nav-item'>
                              <a
                                className='nav-link text-active-primary pb-4'
                                data-kt-countup-tabs='true'
                                data-bs-toggle='tab'
                                href='#Assigned_by_me'
                              >
                                Assigned by me 
                              </a>
                            </li>
                          </ul>
                          <div className='tab-content' id='myTabContent'>
                            <div
                              className='tab-pane fade show active '
                              id='Assigned_to_me'
                              role='tabpanel'
                            >
                              <h3 className='card-title align-items-start flex-column'>
                                <span style={{fontWeight: 'normal'}}>
                                  {' '}
                                  <sub className={''} style={{color: 'black'}}>
                                    {usrRole.includes('Administrator')
                                      ? '(Pending Task assigned to all user`s)'
                                      : `Pending task assigned to me `}
                                  </sub>
                                </span>
                              </h3>

                              <Table
                                columns={columnTemp}
                                dataSource={assigned_to_me}
                                loading={loading}
                                scroll={{
                                  x: 'auto',
                                  y: '600px',
                                }}
                             pagination={false} />
                            </div>
                            <div className='tab-pane fade ' id='Assigned_by_me' role='tabpanel'>
                              <h3 className='card-title align-items-start flex-column'>
                                <span style={{fontWeight: 'normal'}}>
                                  {' '}
                                  <sub className={''} style={{color: 'black'}}>
                                    (Task assigned by me with status as{' '}
                                    <span style={{color: 'rgb(138, 38, 80)'}}>pending</span> )
                                  </sub>
                                </span>
                              </h3>
                              <Table
                                columns={columnAssignByme}
                                dataSource={assigned_by_me}
                                loading={loading}
                                scroll={{
                                  x: 'auto',
                                  y: '600px',
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {(assigned_by_me.length > 0 || assigned_to_me.length > 0) &&
                          !usrRole.includes('RDS-Reviewer') &&
                          !usrRole.includes('Sigma-Reviewer') && (
                            <Link to={{pathname: '/tasks'}}>
                              <button className='btn btn-sm btn-primary '>View More</button>
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export {HomeWrapper}
