import React, {Suspense, lazy, useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {HomeWrapper} from '../pages/mainPages/Home'
import TagListWrapper from '../pages/mainPages/TagList'
import {ForgotPassword} from '../pages/mainPages/ForgotPassword'
import {ResetPassword} from '../pages/mainPages/ResetPassword'
// import SupplierListNew from '../pages/mainPages/SupplierListNew'
// import ProductIdentifier from '../pages/mainPages/ProductIdentifier'
// import PredefinedComment from '../pages/mainPages/PredefinedComment'
// import AllBackground from '../pages/mainPages/AllBackground'

// import Powerbi from '../pages/Powerbi'
// import AllMarketTrends from '../pages/mainPages/AllMarketTrends'
//  import AllBackground from '../pages/mainPages/AllBackground'

let userRole=localStorage.getItem('portalUserDataRole')
export function PrivateRoutes() {
  useEffect(()=>{

  },[userRole==null])
  const BuilderPageWrapper = React.lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const SupplierWrapper = React.lazy(() => import('../pages/mainPages/Supplier'))
  const PriceComparison = React.lazy(() => import('../pages/mainPages/PriceComparison'))
  const RunrateReport =React.lazy(()=>import('../pages/mainPages/RunrateReport'))
  const ProductAnalytical =React.lazy(()=>import('../pages/mainPages/ProductAnalytical'))
  const ProductAnalytics =React.lazy(()=>import('../pages/mainPages/ProductAnalytics'))
  const ProductNew =React.lazy(()=>import('../pages/mainPages/NewProduct/ProductNew'))


  const ProductWrapper = React.lazy(() => import('../pages/mainPages/Product'))
  const SupplierListWrapper = React.lazy(() => import('../pages/mainPages/SupplierList'))
  const SupplierListNew = React.lazy(() => import('../pages/mainPages/SupplierListNew'))

  const ProductListWrapper = React.lazy(() => import('../pages/mainPages/ProductListNew'))
  const ProfileWrapper = React.lazy(() => import('../pages/mainPages/Profile'))
  const POListWrapper = React.lazy(() => import('../pages/mainPages/POList'))
  const POItemsWrapper = React.lazy(() => import('../pages/mainPages/POItems'))
  const UserManageWrapper = React.lazy(() => import('../pages/mainPages/UserManage'))
  const RoleManageWrapper = React.lazy(() => import('../pages/mainPages/RoleManage'))
  const TagWrapper = React.lazy(() => import('../pages/mainPages/Tags'))
  const TaskListWrapper = React.lazy(() => import('../pages/mainPages/TaskList'))
  const TagProductWrapper = React.lazy(() => import('../pages/mainPages/TagProduct'))
  const AllAri=React.lazy(() => import('../pages/mainPages/AllAri'))
  const CaptureDetailsWrapper = React.lazy(() => import('../pages/mainPages/CaptureDetails'))
  const ProductSupplierWrapper = React.lazy(() => import('../pages/mainPages/ProductSupplierDetails'))
  const UsageDetailsWrapper = React.lazy(() => import('../pages/mainPages/UsageDetails'))
  const ProductGrnWrapper = React.lazy(() => import('../pages/mainPages/ProductGrn'))
  const SupplierGrnWrapper = React.lazy(() => import('../pages/mainPages/SupplierGrn'))
  const AllCommentsTask = React.lazy(() => import('../pages/mainPages/AllCommentsTask'))
  const RecentPo = React.lazy(() => import('../pages/mainPages/RecentPo'))
  const Concerto=React.lazy(()=> import('../pages/mainPages/Concerto'))
  const CompetitorPrice=React.lazy(()=> import('../pages/mainPages/CompetitorPrice'))
  const Runrate=React.lazy(()=> import('../pages/mainPages/Runrate'))
  const watchList =React.lazy(()=>import('../pages/mainPages/watchList'));
  const BuyersWatchlist=React.lazy(()=>import('../pages/mainPages/BuyersWhatchlist'))
  const ViewWatchlist =React.lazy(()=>import('../pages/mainPages/ViewWatchlist'))
  const PriceUpdate =React.lazy(()=>import('../pages/mainPages/PriceUpdate'))
  const PriceUpdateNew =React.lazy(()=>import('../pages/mainPages/PriceUpdateNew'))
  const Sigma =React.lazy(()=>import('../pages/mainPages/Sigma'))
  const Sigma1 =React.lazy(()=>import('../pages/mainPages/Sigma'))
  const AllBackground=React.lazy(()=>import("../pages/mainPages/AllBackground"))
  const AllComments=React.lazy(()=>import("../pages/mainPages/AllComments"))
  const AllMarketTrends=React.lazy(()=>import("../pages/mainPages/AllMarketTrends"))
  const powerbi=React.lazy(()=>import("../pages/mainPages/Powerbi"));
  const Identifier =React.lazy(()=>import("../pages/mainPages/Identifier"));
  const PredefinedComment =React.lazy(()=>import("../pages/mainPages/PredefinedComment"));
  const AllBackgroundList =React.lazy(()=>import("../pages/mainPages/AllBackgroundList"));
  const ProductIdentifier =React.lazy(()=>import("../pages/mainPages/ProductIdentifier"));
  const LoginStats =React.lazy(()=>import("../pages/mainPages/LoginStats"));
  const LastReview =React.lazy(()=>import("../pages/mainPages/LastReviewWatchlist"));
  const ViewPricing =React.lazy(()=>import("../pages/mainPages/ViewPricing"));



  return (
    <React.Fragment>
    <Suspense fallback={<FallbackView />}>
      <Switch>LastReview
        <Route exact path='/home' component={ HomeWrapper} />
        <Route exact path='/supplier' component={SupplierWrapper} />
        {/* <Route exact path='/product' component={ProductWrapper} /> */}
        <Route exact path='/product' component={ProductNew} />
        <Route exact path='/reports/pricecomparison' component={PriceComparison} />
        {/* <Route exact path='/reports/watchlist' component={RunrateReport} /> */}
        <Route path='/admin/user' component={UserManageWrapper} />
        <Route exact path='/reset' component={ResetPassword} />
        <Route path='/admin/user' component={UserManageWrapper} />
        <Route exact path='/admin/role' component={RoleManageWrapper} />
        <Route exact path='/admin/sList' component={SupplierListWrapper} />
        <Route exact path='/admin/supplierListNew' component={SupplierListNew} />

        <Route exact path='/admin/pList' component={ProductListWrapper} />
        <Route exact path='/admin/settings' component={BuilderPageWrapper} />
        <Route exact path='/admin/predefinedComment' component={PredefinedComment} />
        <Route exact path='/tag' component={TagWrapper} />
        <Route exact path='/productsupplierDetails' component={ProductSupplierWrapper} />
        <Route exact path='/captureDetails' component={CaptureDetailsWrapper} />
        <Route exact path='/tagDetails' component={TagProductWrapper} />
        <Route exact path='/allari' component={AllAri} />
        <Route exact path='/tagsList' component={TagListWrapper} />
        <Route exact path='/tasks' component={TaskListWrapper} />
        <Route exact path='/poItems' component={POItemsWrapper} />
        <Route exact path='/poList' component={POListWrapper} />
        <Route exact path='/usageDetails' component={UsageDetailsWrapper} />
        <Route exact path='/profile' component={ProfileWrapper} />
        <Route exact path='/product-grn' component={ProductGrnWrapper} />
        <Route exact path='/supplier-grn' component={SupplierGrnWrapper} />
        <Route exact path='/all-comments-task' component={AllCommentsTask} />
        <Route exact path='/all-background' component={AllBackground} />

        <Route exact path='/recent-po' component={RecentPo} />
        <Route exact path='/concerto-mapping' component={Concerto}/>
        <Route exact path='/productanalytics' component={ProductAnalytical}/> 
        <Route exact path='/reports/CompetitorPrice'  component={CompetitorPrice}/>
        <Route exact path='/reports/Runrate'  component={Runrate}/>
        <Route exact path='/reports/createwatchlist'  component={watchList}/>
        <Route exact path="/reports/buyerwatchlist" component={BuyersWatchlist} />
        <Route exact path="/reports/viewwatchlist" component={ViewWatchlist} />
        <Route exact path="/reports/priceupdate" component={PriceUpdate} />
        <Route exact path="/reports/sigma" component={Sigma} />
        <Route exact path="/report/ppp" component={PriceUpdateNew}/>
        <Route exact path="/runrate" component={Runrate} />
        <Route exact path="/product-details" component={ProductAnalytics}/>
        <Route exact path="/comments" component={AllComments}/>
        <Route exact path="/allmarkettrend" component={AllMarketTrends} />
        <Route exact path="/powerbi" component={powerbi} />
        <Route exact path="/identifier" component={Identifier} />
        <Route exact path="/allbackgroundlist" component={AllBackgroundList} />
        <Route exact path="/prodId" component={ProductIdentifier} />
        <Route exact path="/admin/loginstats" component={LoginStats} />
        <Route exact path="/lastreviewWatchlist" component={LastReview} />
        <Route exact path="/view-pricing" component={ViewPricing} />


        {/* <Route exact path='/pricecomparison' component={PriceComparison}/> */}
        {/* <Route exact path='/runrate-report' component={RunrateReport}/> */}
        
        <Redirect exact to='/home' />
      </Switch>
    </Suspense>
      
    </React.Fragment>
  )
}
